import { useState } from "react";
import { Link } from "react-router-dom";
import Cta from "../components/cta";
import GoogleAds from "./components/google-ads";
import WebDesign from "./components/web-design";
import MobileApp from "./components/mobile-app";
import LogoDesign from "./components/logo-design";
import SocialMediaMarketing from "./components/social-media-marketing";
import SiteBranding from "./components/site-branding";
import SocialMedia from "./components/social-media";
import SEO from "./components/seo";

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const renderSelectedService = () => {
    switch (selectedService) {
      case "webDesign":
        return <WebDesign />;
      case "mobileApp":
        return <MobileApp />;
      case "logoDesign":
        return <LogoDesign />;
      case "socialMediaMarketing":
        return <SocialMediaMarketing />;
      case "siteBranding":
        return <SiteBranding />;
      case "socialMedia":
        return <SocialMedia />;
      case "googleAds":
        return <GoogleAds />;
      case "seo":
        return <SEO />;
      default:
        return <WebDesign />;
    }
  };

  const getServiceDetails = () => {
    switch (selectedService) {
      case "webDesign":
        return "Web Design & Development";
      case "mobileApp":
        return "Mobile Apps";
      case "logoDesign":
        return "Logo Design";
      case "socialMediaMarketing":
        return "Social Media Marketing";
      case "siteBranding":
        return "Site Branding";
      case "socialMedia":
        return "Social Media";
      case "googleAds":
        return "Google Ads";
      case "seo":
        return "SEO";
      default:
        return "Web Design & Development";
    }
  };

  return (
    <>
      <section class="breadcrumb-area">
        <div
          class="tg-breadcrumb-bg"
          style={{ backgroundImage: "url('assets/img/others/inner-page-breadcumb.jpg')" }}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="tg-breadcrumb">
                  <h2 class="tg-breadcrumb-title">Services</h2>
                  <div class="tg-breadcrumb-link">
                    <span class="tg-breadcrumb-active">
                      <a href="#">Services</a>
                    </span>
                    <span>{getServiceDetails()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="service pt-120 pb-115">
        <div class="container custom-container">
          <div class="row">
            <div class="col-lg-4 mb-24">
              <div class="sidebar">

                <div class="sidebar-category sidebar__block mb-40">
                  <h3 class="sidebar__title mb-15">Software Development </h3>
                  <ul>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("webDesign")}>Web Design & Dev't</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("mobileApp")}>Mobile  Apps</a>
                    </li>
                  </ul>
                  <br/>
                  <h3 class="sidebar__title mb-15">Graphics Design </h3>
                  <ul>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("logoDesign")}>Logo Design</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("socialMedia")}>Social Media Fliers</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("siteBranding")}>Site Branding</a>
                    </li>
                  </ul>
                  <br/>
                  <h3 class="sidebar__title mb-15">Digital Marketing </h3>
                  <ul>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("socialMediaMarketing")}>Social Media Marketing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("googleAds")}>Google Ads</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => handleServiceClick("seo")}>SEO</a>
                    </li>
                  </ul>
                </div>
                <div
                  class="sidebar-contact"
                  data-bg-image="assets/img/others/sidebar-contact-bg.jpg"
                >
                  <h3 class="sidebar-contact__title text-white mb-20 h2-40">
                    Do you want to work with us?
                  </h3>
                  <p class="sidebar-contact__text text-white mb-25">
                  It's fast, free and very easy!
                  </p>
                  <Link class="sidebar-contact__link" to="/contact">
                    {" "}
                    Contact us{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-8 mb-24">
              {renderSelectedService()}
            </div>
          </div>
        </div>
      </section>

      <Cta />
    </>
  );
};

export default Services;
