import Pricing from "./components/pricing"

const PricingPage = () => {
  return (
    <>
      <section class="breadcrumb-area">
        <div
          class="tg-breadcrumb-bg"
          style={{ backgroundImage: "url('assets/img/others/inner-page-breadcumb.jpg')" }}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="tg-breadcrumb">
                  <h2 class="tg-breadcrumb-title">Pricing Plan</h2>
                  <div class="tg-breadcrumb-link">
                    <span>Pricing</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <Pricing />

    </>
  );
};

export default PricingPage;
