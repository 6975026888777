import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const SiteBranding = () => {

    return (
        <div class="service-details">
        <h2 class="mb-15 service-details__title-big h2-40">
        Site Branding
        </h2>

</div>
    );
}

export default SiteBranding;
