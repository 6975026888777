import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ASSET_URL, axiosPrivate } from "../config/axios";

const Works = () => {
  const [projects, setProjects] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    "Graphics Designing Work"
  );

  useEffect(() => {
    axiosPrivate
      .get("/projects")
      .then((response) => {
        const filteredProjects = response.data.projects.filter((project) =>
          selectedCategory
            ? project.parent_category_title === selectedCategory
            : true
        );
        setProjects(filteredProjects);
      })
      .catch((error) => console.error("Error fetching projects:", error));
  }, [selectedCategory]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const getButtonClass = (category) => {
    return selectedCategory === category ? "selectedBtn" : "btn";
  };

  const getAllServicesLink = () => {
    switch (selectedCategory) {
      case "Graphics Designing Work":
        return "/graphics-design";
      case "Websites Dev't Work":
        return "/website-design";
      default:
        return "/services"; // Fallback or default path if needed
    }
  };

  return (
    <>
      <section class="breadcrumb-area">
        <div
          class="tg-breadcrumb-bg"
          style={{
            backgroundImage:
              "url('assets/img/others/inner-page-breadcumb.jpg')",
          }}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="tg-breadcrumb">
                  <h2 class="tg-breadcrumb-title">Our Works</h2>
                  <div class="tg-breadcrumb-link">
                    <span class="tg-breadcrumb-active">
                      <a href="#">Services</a>
                    </span>
                    <span>Our Works</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="service pt-120 pb-115">
        <div class="container custom-container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="row">
                <div className="row mb-5 justify-content-center">
                  <div className="col-md-3">
                    <div className="service__button pt-15">
                      <button
                        className={getButtonClass("Graphics Designing Work")}
                        onClick={() =>
                          handleCategorySelect("Graphics Designing Work")
                        }
                      >
                        Graphics Designing
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="service__button pt-15">
                      <button
                        className={getButtonClass("Websites Dev't Work")}
                        onClick={() =>
                          handleCategorySelect("Websites Dev't Work")
                        }
                      >
                        Websites Dev't
                      </button>
                    </div>
                  </div>
                </div>
                {projects.map((project, index) => (
                  <div
                    class="col-md-4 mb-24 wow fadeInUp animated"
                    data-wow-duration="500ms"
                    data-wow-delay="200ms"
                  >
                    <div class="project-block project-block--style2">
                      <div class="project-block__image">
                        <a href="project-details.html">
                          <img
                            src={`${ASSET_URL}${project.featured_image}`}
                            alt={project.title}
                          />
                        </a>
                      </div>
                      <div class="project-block__content">
                        <h5>
                        <Link to={`/project/${project.slug}`}>
                          {project.title}
                        </Link>
                        </h5>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Works;
