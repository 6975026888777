import React, { useEffect, useState } from 'react';
import { ASSET_URL, axiosPrivate } from '../../config/axios';
import { Link } from 'react-router-dom';

const Services = () => {
    const [projects, setProjects] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Graphics Designing Work')

    useEffect(() => {
        axiosPrivate.get('/projects')
            .then(response => {
                const filteredProjects = response.data.projects.filter(project =>
                    selectedCategory ? project.parent_category_title === selectedCategory : true
                ).slice(0, 4);
                setProjects(filteredProjects);
            })
            .catch(error => console.error('Error fetching projects:', error));
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const getButtonClass = (category) => {
        return selectedCategory === category ? 'selectedBtn' : 'btn';
    };

    const getAllServicesLink = () => {
        switch(selectedCategory) {
            case 'Graphics Designing Work':
                return '/graphics-design';
            case 'Websites Dev\'t Work':
                return '/website-design';
            default:
                return '/services'; // Fallback or default path if needed
        }
    };

    return (
        <>
            <section class="service service--bg position-relative fix pt-120 pb-96">
                <div class="container custom-container">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-10 mb-24">
                            <div class="section-title animation-style2">
                                <span class="section-title__sub primary-color mb-10">OUR PROJECT</span>
                                <h2 class="section-title__main text-white">Our Latest Projects</h2>
                            </div>
                            <div class="service__content">
                                <p class="service__text pt-15 text-color-gray">Our projects showcase a blend of creativity and precision, turning bold ideas into reality. Discover our commitment to excellence through our latest ventures, each a reflection of our innovative approach and dedication to impactful solutions.
                                </p>
                                <div className="service__button pt-15">
                                <Link to={getAllServicesLink()} className="btn">See All Projects</Link>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div className="row mb-5">
                                    <div className="col-md-4">
                                        <div className="service__button pt-15">
                                            <button className={getButtonClass('Graphics Designing Work')} onClick={() => handleCategorySelect('Graphics Designing Work')}>Graphics Designing</button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="service__button pt-15">
                                            <button className={getButtonClass('Websites Dev\'t Work')} onClick={() => handleCategorySelect('Websites Dev\'t Work')}>Websites Dev't</button>
                                        </div>
                                    </div>
                                </div>
                                {projects.map((project, index) => (
                                    <div class="col-md-6 mb-24 wow fadeInUp animated" data-wow-duration="500ms" data-wow-delay="200ms">
                                        <div class="service-block p-2">
                                            <img src={`${ASSET_URL}${project.featured_image}`}style={{
                                                width: "350px",
                                                height: "350px",
                                            }}
                                                alt={project.title} class="blog-block__img" />
                                            <h4 class="service-block__title text-white mb-0 pt-20">
                                                <Link
                                                    to={`/project/${project.slug}`}>{project.title}</Link>
                                            </h4>
                                            {/* <p class="service-block__text text-color-gray mb-0 pt-10">
                                            Make a lasting impression with captivating visuals and compelling branding.
                                        </p> */}
                                            {/* <div class="service-block__footer pt-10">
                                                <Link to="/graphics-design" class="service-block__link">Category: {project.category_title}</Link >
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="element element--service" data-parallax='{"y" : 50 }'></div>
                <div class="element element--service element--service2" data-parallax='{"y" : 100 }'></div>
                <div class="element element--service element--service3" data-parallax='{"y" : 200 }'></div>
            </section>
        </>
    )
}
export default Services;
