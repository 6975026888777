import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const LogoDesign = () => {

    return (
        <div class="service-details">
                <h2 class="mb-15 service-details__title-big h2-40">
                Creative Brand Logos
                </h2>
                <p class="service-details__text-main mb-20">
                A logo is a visually appealing mark, emblem, or symbol used to support and encourage public recognition and identification. It could be an abstract or figurative design, or it could be a wordmark with the name it stands for written inside.
                </p>

                <img
                  class="service-details__banner mb-35 rounded-4"
                  src="assets/img/others/logo-design.jpg"
                  alt="Service Details"
                />
                <h3 class="mb-15 service-details__title-small">
                Unique And Experienced Outputs
                </h3>
                <p class="service-details__text-sub mb-30">
                    We do the best to provide the best for our customers. Uniqueness is vial in what ever we do that is why we ensure the best is gotten.
                </p>
                <hr />
                <h3 class="mb-25 service-details__title-small">
                    How To Get A Logo From Kim Digitary.
                </h3>
                <div class="faq-block faq-block--style2">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Step 1: We learn about your brand.
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        We take time to learn about your business to have vital knowledge so that we come up with the basis of the new design


                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Step 2: You choose a design
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        We provide a sketch library for you to make a selection of your choice iin terms of design and colors to be used on the new design.


                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Step 3: We make it for you
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        With in a few hours we use the provided information to design and deliver a fabulous logo design for your business.


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
}

export default LogoDesign;
