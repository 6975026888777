import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/kimdigitary.png";

const Header = () => {
 const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
 const [dropdownVisibility, setDropdownVisibility] = useState({});

 // Function to toggle the mobile menu visibility
 const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
 };

 // Function to toggle the visibility of a specific dropdown menu
 const toggleDropdown = (dropdownId) => {
    setDropdownVisibility(prevState => ({
      ...prevState,
      [dropdownId]: !prevState[dropdownId]
    }));
 };

 // Effect to add or remove the class from the body element based on the state
 useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
 }, [isMobileMenuOpen]);


  return (
    <>
      <div id="header-fixed-height"></div>
      <header>
        <div id="sticky-header" className="tg-header__area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <i className="flaticon-menu-1"></i>
                </div>
                {isMobileMenuOpen && (
                  <>
                    <div className="tgmobile__menu">
                      <nav className="tgmobile__menu-box">
                        <div className="close-btn" onClick={toggleMobileMenu}>
                          <i className="flaticon-close-1"></i>
                        </div>
                        <div class="nav-logo">
                          <Link to="index.html">
                            <img src="assets/img/logo/logo.png" alt="Logo" />
                          </Link>
                        </div>
                        <div class="tgmobile__search">
                          <form action="#">
                            <input type="text" placeholder="Search here..." />
                            <button type="submit">
                              <i class="fas fa-search"></i>
                            </button>
                          </form>
                        </div>
                        <div class="tgmobile__menu-outer">
                          <ul class="navigation">
                            <li class="menu-item-has-children">
                              <Link to="/" class="active-menu">
                                Home
                              </Link>
                            </li>
                            <li class="menu-item-has-children">
                              <Link to="/services">Services</Link>
                              {/* <ul class="sub-menu" style={{ display: dropdownVisibility.services ? 'block' : 'none' }}>
                                <li>
                                  <Link to="#">Web Design</Link>
                                  <ul class="sub-menu" style={{ display: dropdownVisibility.webdesign ? 'block' : 'none' }}>
                                    <li>
                                      <Link to="/web-design">
                                        Web Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/mobile-apps">
                                        Mobile Applications
                                      </Link>
                                    </li>
                                  </ul>
                                  <div class={`dropdown-btn ${dropdownVisibility.webdesign ? 'open' : ''}`} onClick={() => toggleDropdown('webdesign')}><span class="plus-line"></span></div>
                                </li>
                                <li>
                                  <Link to="#">Graphics Design</Link>
                                  <ul class="sub-menu" style={{ display: dropdownVisibility.graphicsdesign ? 'block' : 'none' }}>
                                    <li>
                                      <Link to="/logo-design">Logo Design</Link>
                                    </li>
                                    <li>
                                      <Link to="/social-media-posting">
                                        Social Media Posting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/site-branding">
                                        Site Branding
                                      </Link>
                                    </li>
                                  </ul>
                                  <div class={`dropdown-btn ${dropdownVisibility.graphicsdesign ? 'open' : ''}`} onClick={() => toggleDropdown('graphicsdesign')}><span class="plus-line"></span></div>
                                </li>
                                <li>
                                  <Link to="#">Digital Marketing</Link>
                                  <ul class="sub-menu" style={{ display: dropdownVisibility.digitalmarketing ? 'block' : 'none' }}>
                                    <li>
                                      <Link to="/social-media-marketing">
                                        Social Media Marketing
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/google-ads">Google Ads</Link>
                                    </li>
                                    <li>
                                      <Link to="/seo">SEO</Link>
                                    </li>
                                  </ul>
                                  <div class={`dropdown-btn ${dropdownVisibility.digitalmarketing ? 'open' : ''}`} onClick={() => toggleDropdown('digitalmarketing')}><span class="plus-line"></span></div>
                                </li>
                              </ul>
                              <div class={`dropdown-btn ${dropdownVisibility.services ? 'open' : ''}`} onClick={() => toggleDropdown('services')}><span class="plus-line"></span></div> */}
                            </li>
                            <li class="menu-item-has-children">
                              <Link to="/our-works">Our Work </Link>
                              {/* <ul class="sub-menu" style={{ display: dropdownVisibility.work ? 'block' : 'none' }}>
                                <li>
                                  <Link to="/works/web-design">Web Design</Link>
                                </li>
                                <li>
                                  <Link to="/works/graphics-design">
                                    Graphics Design
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/works/web-apps">Web Apps</Link>
                                </li>
                              </ul>
                              <div class={`dropdown-btn ${dropdownVisibility.work ? 'open' : ''}`} onClick={() => toggleDropdown('work')}><span class="plus-line"></span></div> */}
                            </li>
                            <li class="menu-item-has-children">
                              <Link to="about">About </Link>
                            </li>
                            <li class="menu-item-has-children">
                              <Link to="pricing"> Pricing </Link>
                            </li>
                            <li>
                              <Link to="blog">Blog</Link>
                            </li>
                          </ul>
                        </div>
                        <div class="social-links">
                          <ul class="list-wrap">
                            <li>
                              <Link to="project-details.html">
                                <i class="fab fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="project-details.html">
                                <i class="fab fa-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="project-details.html">
                                <i class="fab fa-instagram"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="project-details.html">
                                <i class="fab fa-linkedin-in"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="project-details.html">
                                <i class="fab fa-youtube"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                    <div class="tgmobile__menu-backdrop"></div>
                  </>
                )}

                <div class="tgmenu__wrap">
                  <nav class="tgmenu__nav">
                    <div class="logo">
                      <Link to="index.html">
                        <img src="assets/img/logo/logo.png" alt="Logo" />
                      </Link>
                    </div>
                    <div class="tgmenu__navbar-wrap tgmenu__main-menu d-none d-lg-flex">
                      <ul class="navigation">
                        <li class="menu-item-has-children">
                          <Link to="/" class="active-menu">
                            Home
                          </Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="/services">Services</Link>
                          {/* <ul class="sub-menu">
                            <li>
                              <Link to="#">Web Design</Link>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="/web-design">Web Development</Link>
                                </li>
                                <li>
                                  <Link to="/mobile-apps">
                                    Mobile Applications
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Graphics Design</Link>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="/logo-design">Logo Design</Link>
                                </li>
                                <li>
                                  <Link to="/social-media-posting">
                                    Social Media Posting
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/site-branding">Site Branding</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Digital Marketing</Link>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="/social-media-marketing">
                                    Social Media Marketing
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/google-ads">Google Ads</Link>
                                </li>
                                <li>
                                  <Link to="/seo">SEO</Link>
                                </li>
                              </ul>
                            </li>
                          </ul> */}
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="/our-works">Our Work </Link>
                          {/* <ul class="sub-menu">
                            <li>
                              <Link to="/works/web-design">Web Design</Link>
                            </li>
                            <li>
                              <Link to="/works/graphics-design">
                                Graphics Design
                              </Link>
                            </li>
                            <li>
                              <Link to="/works/web-apps">Web Apps</Link>
                            </li>
                          </ul> */}
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="about">About </Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="pricing"> Pricing </Link>
                        </li>
                        <li>
                          <Link to="blog">Blog</Link>
                        </li>
                      </ul>
                    </div>
                    <div class="tgmenu__action d-none d-md-block">
                      <ul class="list-wrap">
                        <li class="header-search">
                          <Link to="#" class="search search--button">
                            <img
                              src="assets/img/icons/search-icon.svg"
                              alt="Search"
                            />
                          </Link>
                        </li>
                        <li class="header-btn">
                          <Link to="/contact" class="btn">
                            Let's Talk
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <form action="#" class="search-block">
                  <div class="search-block__container">
                    <span id="close-search" class="search-block__close">
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    <div class="search-block__input">
                      <input
                        class="search-block__field-input"
                        type="text"
                        placeholder="Enter Your Keywords..."
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
