import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const SocialMediaMarketing = () => {

    return (
        <div class="service-details">
                <h2 class="mb-15 service-details__title-big h2-40">
                Social Media Marketing
                </h2>

        </div>
    );
}

export default SocialMediaMarketing;
