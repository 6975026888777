import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const WebDesign = () => {

    return (
        <div class="service-details">
                <h2 class="mb-15 service-details__title-big h2-40">
                    Website Designing & Development
                </h2>
                <p class="service-details__text-main mb-20">
                There are variety of reasons why our website development services are the best every business deserves, See some below:
                </p>
                <div class="list-block">
                  <div class="list-block__column">
                    <ul>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>OUTSTANDING LOOKS</span>
                      </li>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>SECURITY A PRIORITY</span>
                      </li>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>COST EFFECTIVE PLANS</span>
                      </li>
                    </ul>
                  </div>
                  <div class="list-block__column">
                    <ul>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>TRUST</span>
                      </li>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>DEVICE RESPONSIVE</span>
                      </li>
                      <li>
                        <i class="fa-solid fa-check"></i>
                        <span>INSTANT SUPPORT</span>
                      </li>
                    </ul>
                  </div>
                </div><br/>
                <p class="service-details__text-sub mb-30">
                    Owning a website for your small businesses is being where your consumers be. 80 percent of people go online to find a local business, over 3.5 billion searches on Google per day, and at this  moment, there is someone in your area online and searching for your exact service.
                </p>
                <img
                  class="service-details__banner mb-35 rounded-4"
                  src="assets/img/others/service-details-banner.jpg"
                  alt="Service Details"
                />

                <hr />
                <h3 class="mb-25 service-details__title-small">
                Why You Need A Website
                </h3>
                <div class="faq-block faq-block--style2">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Some clients may not consider you without one
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        A website is the number one marketing asset since we live in a digital age. People spend many hours online per week and are always on their mobile devices. Consumers expect companies to have an online presence a website inclusive and will consider a company LACKING one as less professional.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          People are always searching for you online
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        Owning a website for your small businesses is being where your consumers be. 80 percent of people go online to find a local business, over 3.5 billion searches on Google per day, and at this  moment, there is someone in your area online and searching for your exact service.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Your Website Stays Open 24 Hours Per Day
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        Unlike your business, which most likely does not operate or communicate with customers after-hours, your website stays open 24 hours per day, 7 days per week. This means that even when your staff is not available to interact with people looking to do business with you, your website is accessible and able to provide helpful information, serve as a point of contact or even allow customers to make purchases or submit orders.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          It Helps Your Business Grow
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        If you’re hoping to expand your business and bring in new customers, having a website is an important strategy. Small businesses who’ve experimented with launching a website report being happy with their success.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Saving You Time + Customer Service
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        Many businesses get calls from prospects or existing customers asking simple questions about location and hours of operation. If you miss a call, the customer is left unhappy. Calls can also distract your staff from focusing on the most important parts of your business. A website can reduce these calls and increase internal productivity. At the same time, it helps customers find useful information without needing to call, which ultimately provides an all-around better user experience.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Updates And Announcements
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        Since your website is on 24/7, it's easy to post updates and announcements to your customers. It's a way to keep them up to date on everything that you're doing. When something is particularly relevant to them, it increases the chance of you being able to upsell them.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
}

export default WebDesign;
